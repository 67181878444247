import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

import UUID from "vue-uuid";
Vue.use(UUID);

import VueMask from 'v-mask'
Vue.use(VueMask, {
    placeholders: {
      'w': /[a-zA-Z\u00c4\u00d6\u00dc\u00e4\u00f6\u00fc\u00df0-9_-]/,  
    }
  })
  
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
    <v-app>
        <v-app-bar app flat color="white">
            <div class="d-flex align-center">
                <v-img
                    alt="Vuetify Logo"
                    class="mr-2"
                    contain
                    src="https://oknrw.de/wordpress/wp-content/uploads/2020/07/OKNRW.png"
                    transition="scale-transition"
                    width="300"
                />
            </div>
            <v-spacer></v-spacer>

            <v-btn
                v-for="link in links"
                :key="link.url"
                @click="goto(link.url)"
                text
            >
                <v-icon color="" left>{{ link.icon }}</v-icon>

                {{ link.name }}
            </v-btn>
        </v-app-bar>

        <v-main>
            <v-container fluid class="">
                <v-row>
                    <v-col cols="12" md="9" class="pa-3">
                        <vue-word-cloud
                            style="height: 480px; width: 100%"
                            :words="cloud"
                            :animation-duration="500"
                            :rotation="rotateWord"
                            :color="
                                ([, weight]) =>
                                    weight > 30
                                        ? '#264653'
                                        : weight > 20
                                        ? '#2a9d8f'
                                        : weight > 10
                                        ? '#e9c46a'
                                        : weight > 2
                                        ? '#f4a261'
                                        : '#e76f51'
                            "
                            font-family="Merriweather"
                        />
                        <span class="caption">
                          Du siehst Hashtags von {{amount}} Teilnehmenden
                        </span>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-card
                            tile
                            class="text-uppercase"
                            elevation="3"
                            max-width=""
                        >
                            <v-toolbar flat color="primary" dark>
                                Deine Hashtags
                            </v-toolbar>
                            <v-card-text>
                                <v-text-field

                                    v-for="i in [0, 1, 2]"
                                    :key="i"
                                    type=""
                                    placeholder=""
                                    v-model="hashtags[i]"
                                    prefix="#"
                                    v-mask="'wwwwwwwwwwwwwwwwwwww'"
                                    filled
                                    @input="changed = true"
                                    @keyup.enter="send"
                                    color
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    @click="send"
                                    elevation=""
                                    tile
                                    :disabled="!changed"
                                    color="primary"
                                >
                                    <v-icon left color="">mdi-send</v-icon>

                                    Senden</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                        <v-img
                            alt="Audiospace"
                            contain
                            @click="goto('https://map.highfidelity.com/BMEx65k19756uV27/?map=https%3A%2F%2Fmrtopf.de%2Foknrwbg.png')"
                            src="@/assets/audio.png"
                            transition="scale-transition"
                            class="d-flex justify-end mt-4"
                            width="100%"
                        >
                            <v-btn class="ma-4" elevation="" color="" tile
                                >zum Audiospace</v-btn
                            >
                        </v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <v-footer class="caption">
            <v-card flat color="transparent">
                <v-card-text>
                    &copy; 2020
                    <a href="https://twitter.com/mrtopf">Christian Scholz</a> /
                    <a href="https://comlounge.net">COM.lounge GmbH</a>,
                    <a target="_blank" href="https://comlounge.net/kontakt"
                        >Impressum</a
                    >
                </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import VueWordCloud from "vuewordcloud";
import axios from "axios";
import Chance from "chance";

export default {
    name: "App",

    components: {
        VueWordCloud,
    },
    async mounted() {
        // create uniq user id if it does not exist
        this.userid = localStorage.getItem("wordcloudid");
        if (!this.userid) {
            this.userid = this.$uuid.v4();
            localStorage.setItem("wordcloudid", this.userid);
        }
        axios.get(`/api/users/${this.userid}`).then((response) => {
            this.hashtags = response.data.tags;
        });

        this.getCloud();
        setInterval(
            function () {
                this.getCloud();
            }.bind(this),
            5000
        );
    },
    methods: {
        goto(url) {
            window.open(url, "oknrw");
        },
        rotateWord(word) {
            var chance = new Chance(word[0]);
            return chance.pickone([0, 1 / 8, 3 / 4, 7 / 8]);
        },
        send() {
            axios
                .post(`/api/users/${this.userid}`, { tags: this.hashtags })
                .then((response) => {
                    this.hashtags = response.data.tags;
                    this.changed = false;
                    this.getCloud();
                });
        },
        getCloud() {
            axios.get("/api/cloud").then((response) => {
                this.cloud = response.data.tags;
                this.amount = response.data.amount;
            });
        },
    },
    data: () => ({
        userid: null,
        amount: null,
        changed: false,
        cloud: [],
        hashtags: [],
        links: [
            {
                name: "OKNRW Homepage",
                url:
                    "https://oknrw.de/veranstaltungen/offene-kommunen-nrw-2021/",
                icon: "mdi-home",
            },
            {
                name: "Sessionplan",
                url:
                    "https://oknrw.de/veranstaltungen/offene-kommunen-nrw-2021/programmablauf/",
                icon: "mdi-table",
            },
            {
                name: "Audiospace",
                url:
                    "https://map.highfidelity.com/BMEx65k19756uV27/?map=https%3A%2F%2Fmrtopf.de%2Foknrwbg.png",
                icon: "mdi-headphones",
            },
        ],

        //
    }),
};
</script>
